import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
// import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const data = {
	name: 'Jamie Davies',
	title: 'Full Stack Developer',
	email: 'jamie.davies1@live.co.uk',
	// address: '123 Fake Street',
	// phone: '01111 111111',

	socials: {
		Twitter: {
			link: 'https://www.twitter.com/jamiedavies_dev',
			text: 'jamiedavies_dev',
			icon: <TwitterIcon />,
		},
		Instagram: {
			link: 'https://www.instagram.com/jd_drift',
			text: 'jd_drift',
			icon: <InstagramIcon />,
		},
		LinkedIn: {
			link: 'https://www.linkedin.com/in/jamie-davies-7a54a0202/',
			text: 'Jamie Davies',
			icon: <LinkedInIcon />,
		},
		// GitHub: {
		// 	link: 'https://github.com/jamiedavies-dev',
		// 	text: 'jamiedavies-dev',
		// 	icon: <GitHubIcon />,
		// },
	},

	about: `
		Hello 👋 I'm Jamie! I am a self taught programmer, focused on full development web development.\n 
		I have a passion for bringing designs to life through code, learning new and upcoming technologies and automating repeatable tasks to improve efficiency.\n
		I am currently working as a full stack developer for BookMyGarage.com, as well as various freelance clients.\n
	`,
	workHistory: [
		{
			title: 'Full Stack Developer',
			company: 'BookMyGarage.com',
			date: '2022 - Present',
			location: 'Portsmouth, United Kingdom',
		},
		{
			title: 'Freelance',
			// company: 'n/a',
			date: '2019 - Present',
			location: 'Fareham, United Kingdom',
		},
	],
	educationHistory: [
		{
			title: 'BSc Computing & IT (Software)',
			date: '2021 - Current',
			description:
				'Studying with the Open University, I anticipate to finish my degree by 2024.',
		},
		{
			title: 'Sonny Sangha - Full Stack Hero Course',
			date: '2021 - 2021',
			description:
				'Sonny teaches everything from the basic foundations of Javascript with React, to hooks, redux, databases and RESTful APIs.',
		},
		{
			title: 'Self Taught',
			date: '2016 - Current',
			description:
				'Self taught programming skills. Learning something new every day by self motivated research and building personal projects which can be seen on the Portfolio page.',
		},
	],

	skills: [
		{
			title: 'Front-End',
			description: ['JavaScript', 'React', 'NextJS', 'Tailwind CSS'],
		},
		{
			title: 'Back-End',
			description: ['NodeJS', 'Express', 'REST'],
		},
		{
			title: 'Databases',
			description: ['SQL (Postgres & MySQL)', 'NoSQL (DynamoDB, MongoDB & Firebase)'],
		},
		{
			title: 'Other',
			description: ['Git & Version Control', 'AWS', 'Adobe Photoshop'],
		},
	],

	projects: [
		{
			title: 'Airbnb Clone',
			tags: ['NextJS', 'Clone'],
			images: [
				'https://i.imgur.com/HUg0pv0.jpg',
				'https://i.imgur.com/5LULHpo.png',
				'https://i.imgur.com/rcz075N.png',
				'https://i.imgur.com/v159bGr.png',
			],
			caption: 'A visual demo of an airbnb clone using server side rendering.',
			description:
				'Using NextJS, TailwindCSS and Mapbox, I created a working demo of the airbnb homepage and search functionality. NextJS is a server side rendering framework for React.',
			links: [
				{
					link: 'https://airbnb-clone-frag.vercel.app/',
					icon: <h6>Live Demo</h6>,
				},
			],
		},
		{
			title: 'TCG Collector App',
			tags: ['Personal Project', 'React Native', 'NodeJS'],
			images: ['https://pbs.twimg.com/media/EvPlva8WQAIEzNV?format=jpg&name=medium'],
			caption: 'An application for the tracking of your Pokemon TCG collection.',
			description:
				'Built using React Native with a NodeJS and MongoDB backend, this application features user authentication, collection storage, RESTful API usage and more. Still a work in progress that may develop into a slightly different application than first imagined.',
			links: [
				// {
				// 	link: 'https://www.google.com',
				// 	icon: <GitHubIcon />
				// },
			],
		},
		{
			title: 'My Portfolio',
			tags: ['React', 'Personal Project'],
			images: [
				'https://i.imgur.com/Ly6fqZM.png',
			],
			caption: 'A showcase of my skills, experience and previous works.',
			description:
				'This very portfolio that you are currently browsing was built from the ground up using React. It incorporates all of the basic fundamentals of Mobile First design and is completely responsive. It uses a static data file for information, and is build using reusable functional components, material UI elements and also makes use of router and state management.',
			links: [
				// {
				// 	link: 'https://www.google.com',
				// 	icon: <GitHubIcon />
				// },
			],
		},
		{
			title: 'Spotify NextJS Clone',
			tags: ['NextJS', 'Clone'],
			images: ['https://i.imgur.com/JYhAQFR.png'],
			caption: 'A visual and functional clone of Spotify Web Player.',
			description:
				'Build using NextJS and TailwindCSS, this clone of the Spotify web player uses the Spotify API to authenticate the user, gather their playlists and even play music (Premium Account Required). Using server side rendering and middleware to ensure the users access token is always valid.',
			links: [
				// {
				// 	link: 'https://www.google.com',
				// 	icon: <GitHubIcon />
				// },
				// {
				// 	link: 'https://www.google.com',
				// 	icon: <GitHubIcon />
				// }
			],
		},
		{
			title: 'Saxon Landscapes',
			tags: ['NextJS', 'Personal Project'],
			images: ['https://i.imgur.com/oIHr6Zc.png'],
			caption: 'A responsive website for Saxon Landscapes.',
			description:
				'A responsive website for Saxon Landscapes to gain a web presence, and allow customers to request a call back.',
			links: [
				{
					link: 'https://saxonlandscapes.com/',
					icon: <h6>Live Demo</h6>,
				},
			],
		},
		// {
		// 	tags: ['NextJS', 'Clone'],
		// 	images: [
		// 		'https://www.open.edu/openlearn/ocw/pluginfile.php/1317197/mod_resource/content/0/sc_1_olhp_786px.jpg',
		// 		'https://www.open.edu/openlearn/ocw/pluginfile.php/1317197/mod_resource/content/0/sc_1_olhp_786px.jpg',
		// 	], title: 'Project 1',
		// 	caption: 'short description',
		// 	description: 'Some description, duhh.',
		// 	links: [
		// 		{
		// 			link: 'https://www.google.com',
		// 			icon: <GitHubIcon />
		// 		},
		// 		{
		// 			link: 'https://www.google.com',
		// 			icon: <GitHubIcon />
		// 		}
		// 	]
		// },
	],
};

export default data;
