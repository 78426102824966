import React from 'react';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
} from '@material-ui/lab';
import './timeline.css';
import { Typography } from '@material-ui/core';

const CustomTimeline = ({ title, icon, children }) => {
	return (
		<Timeline className="timeline">
			{/* Item Header */}
			<TimelineItem className="timeline_firstItem">
				<TimelineSeparator>
					<TimelineDot className="timeline_dot_header">
						{icon}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<Typography variant="h6" className="timeline_header">
						{title}
					</Typography>
				</TimelineContent>
			</TimelineItem>

			{children}
		</Timeline>
	);
};

export const CustomTimelineSeparator = () => {
	return (
		<TimelineSeparator className="separator_padding">
			<TimelineDot className="timeline_dot" />
			<TimelineConnector />
		</TimelineSeparator>
	);
};

export default CustomTimeline;
