import React from 'react';
import './header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { AlternateEmail, HomeRounded } from '@material-ui/icons';
import resumeData from '../../utils/resumeData';
import CustomButton from '../Button/Button';

const Header = (props) => {
	const pathName = props?.location?.pathname;

	return (
		<Navbar expand="lg" sticky="top" className="header bottom_40">
			<Nav.Link as={NavLink} to="/" className="header_navlink">
				<Navbar.Brand className="header_home">
					<HomeRounded />
				</Navbar.Brand>
			</Nav.Link>

			<Navbar.Toggle />
			<Navbar.Collapse className="header_collapse">
					<Nav className="header_left">
						{/* Resume Link */}
						<Nav.Link
							as={NavLink}
							to="/"
							className={pathName === '/' ? 'header_link_active' : 'header_link'}
						>
							Resume
						</Nav.Link>
						{/* Portfolio Link */}
						<Nav.Link
							as={NavLink}
							to="/portfolio"
							className={pathName === '/portfolio' ? 'header_link_active' : 'header_link'}
						>
							Portfolio
						</Nav.Link>
					</Nav>

					<div className="header_right">
						{Object.keys(resumeData.socials).map((key) => (
							<a href={resumeData.socials[key].link}>{resumeData.socials[key].icon}</a>
						))}
						<a href="mailto:jamie.davies1@live.co.uk" className="blank_link" tabIndex={-1}>
							<CustomButton text="Let's Talk" icon={<AlternateEmail />} tab />
						</a>
					</div>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default withRouter(Header);
