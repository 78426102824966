import { Typography } from '@material-ui/core';
import React from 'react';
import './footer.css';

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer_left">
				<Typography className="footer_text">&copy; 2023 Jamie Davies</Typography>
			</div>
			<div className="footer_right">
				<Typography className="footer_text">Written in React</Typography>
				{/* <Typography className="footer_text">Based on Salman Fazal's Design</Typography> */}
			</div>
		</div>
	);
};

export default Footer;
