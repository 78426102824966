import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { Typography } from '@material-ui/core';
import './resume.css';
import resumeData from '../../utils/resumeData';
import CustomTimeline, { CustomTimelineSeparator } from '../../components/Timeline/Timeline';
import WorkIcon from '@material-ui/icons/Work';
import { TimelineContent, TimelineDot, TimelineItem } from '@material-ui/lab';
import { School } from '@material-ui/icons';
// import CustomButton from '../../components/Button/Button';

const Resume = () => {
	return (
		<>
			{/* About Me */}
			<Grid container className="section pt_45 pb_45">
				<Grid item className="section_title bottom_30">
					<h6 className="section_title_text">
						About Me
						<span></span>
					</h6>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2" className="aboutme_text">
						{resumeData.about}
					</Typography>
				</Grid>
			</Grid>

			{/* Skills */}
			<Grid container className="section graybg pb_45 p_50">
				<Grid item xs={12}>
					<Grid item className="section_title bottom_30">
						<h6 className="section_title_text">
							Skills
							<span></span>
						</h6>
					</Grid>
					<Grid container justifyContent="space-between" spacing={3}>
						{resumeData.skills.map((skill) => (
							<Grid item xs={12} sm={6} md={3}>
								<Paper elevation={0} className="skill">
									<Typography variant="h6" className="skill_title">
										{skill.title}
									</Typography>
									{skill.description.map((element) => (
										<Typography variant="body2" className="skill_description">
											<TimelineDot
												variant="outlined"
												className="timeline_dot"
											/>
											{element}
										</Typography>
									))}
								</Paper>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>

			{/* Education / Experience */}
			<Grid container className="section pt_45">
				<Grid item className="section_title bottom_30">
					<h6 className="section_title_text">
						Resume
						<span></span>
					</h6>
				</Grid>
				<Grid item xs={12}>
					<Grid container className="resume_timeline">
						{/* Work History */}
						<Grid item sm={12} md={6}>
							<CustomTimeline title="Relevant Work Experience" icon={<WorkIcon />}>
								{resumeData.workHistory.map((work) => (
									<TimelineItem>
										<CustomTimelineSeparator />
										<TimelineContent className="timeline_content">
											<Typography className="timeline_title">
												{work.title}
											</Typography>
											{work.company && (
												<Typography className="timeline_company">
													{work.company}
												</Typography>
											)}
											<Typography className="timeline_date">
												{work.date}
											</Typography>
											<Typography
												variant="body2"
												className="timeline_location"
											>
												{work.location}
											</Typography>
										</TimelineContent>
									</TimelineItem>
								))}
							</CustomTimeline>
						</Grid>
						{/* Education History */}
						<Grid item sm={12} md={6}>
							<CustomTimeline title="Education History" icon={<School />}>
								{resumeData.educationHistory.map((work) => (
									<TimelineItem>
										<CustomTimelineSeparator />
										<TimelineContent className="timeline_content">
											<Typography className="timeline_title">
												{work.title}
											</Typography>
											<Typography variant="caption" className="timeline_date">
												{work.date}
											</Typography>
											<Typography
												variant="body2"
												className="timeline_description"
											>
												{work.description}
											</Typography>
										</TimelineContent>
									</TimelineItem>
								))}
							</CustomTimeline>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* Contact Form */}
			{/*
			<Grid container className="section pb_45 pt_45 graybg">
				<Grid item xs={12} lg={12}>
					<Grid container>
						<Grid item className="section_title bottom_30">
							<h6 className="section_title_text">
								Contact Form
								<span></span>
							</h6>
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField fullWidth name="name" label="Name" />
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField fullWidth name="email" label="Email" />
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										name="message"
										label="Message"
										multiline
										rows={4}
									/>
								</Grid>
								<Grid item xs={12}>
									<CustomButton text="Submit" icon={<Check />} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{/* Contact Info */}
				{/* <Grid item xs={12} lg={5}>
					<Grid item className="section_title bottom_30">
						<h6 className="section_title_text">
							Contact Info
							<span></span>
						</h6>
					</Grid>
				</Grid>
			</Grid>*/}
		</>
	);
};

export default Resume;
