import React from 'react';
import { Button } from '@material-ui/core';
import './button.css';

const CustomButton = ({ text, icon }) => {
	return (
		<Button
			className="customBtn"
			endIcon={
				icon ? (
					<div className="customBtn_iconContainer">{icon}</div>
				) : null
			}
		>
			<span className="customBtn_text">{text}</span>
		</Button>
	);
};

export default CustomButton;
