import React from 'react';
import { Container, Grid } from '@material-ui/core';
// Components
import Profile from './components/Profile/Profile';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
// Pages
import Portfolio from './pages/Portfolio/Portfolio';
import Resume from './pages/Resume/Resume';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

function App() {
	return (
		<Container>
			<Grid container spacing={7} className="top_40">
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<Profile />
				</Grid>
				<Grid item xs>
					<Router>
						<Header />
						<div className="main_content container_shadow bottom_40">
							<Switch>
								<Route exact path="/">
									<Resume />
								</Route>
								<Route exact path="/portfolio">
									<Portfolio />
								</Route>
							</Switch>
						</div>
					</Router>
					<Footer />
				</Grid>
			</Grid>
		</Container>
	);
}

export default App;
