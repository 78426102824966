import React from 'react';
import { Typography } from '@material-ui/core';
import profile_pic from '../../assets/images/profile_pic.jpg';
import CustomTimeline, { CustomTimelineSeparator } from '../Timeline/Timeline';
import resumeData from './../../utils/resumeData';
import { TimelineItem, TimelineContent } from '@material-ui/lab';

import './profile.css';
import { AccountCircle, AlternateEmail } from '@material-ui/icons';
import CustomButton from '../Button/Button';

const CustomTimelineItem = ({ title, text, link }) => (
	<TimelineItem className="timeline_firstItem">
		<CustomTimelineSeparator />
		<TimelineContent className="timeline_content">
			{link ? (
				<Typography className="timelineItem_text">
					<span>{title}: </span>
					<a href={link} target="_blank" rel="noreferrer">
						{text}
					</a>
				</Typography>
			) : (
				<Typography className="timelineItem_text">
					<span>{title}:</span> {text}
				</Typography>
			)}
		</TimelineContent>
	</TimelineItem>
);

const Profile = () => {
	return (
		<div className="profile container_shadow">
			<div className="profile_name">
				<Typography className="name">{resumeData.name}</Typography>
				<Typography className="title">{resumeData.title}</Typography>
			</div>

			<div className="profile_image">
				<img src={profile_pic} alt="Profile Pic" />
			</div>

			<div className="profile_information">
				<CustomTimeline icon={<AccountCircle />}>
					<CustomTimelineItem title="Name" text={resumeData.name} />
					<CustomTimelineItem title="Title" text={resumeData.title} />
					<CustomTimelineItem title="Email" text={resumeData.email} />

					{Object.keys(resumeData.socials).map((key) => (
						<CustomTimelineItem
							title={key}
							text={resumeData.socials[key].text}
							link={resumeData.socials[key].link}
						/>
					))}
				</CustomTimeline>

				<div className="btnContainer">
					<a href="mailto:jamie.davies1@live.co.uk" className="blank_link" tabIndex="-1">
						<CustomButton text="Contact Me" icon={<AlternateEmail />} />
					</a>
				</div>
			</div>
		</div>
	);
};

export default Profile;
