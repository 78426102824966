import { Grid, Tabs, Tab, Card, CardActionArea, CardContent, CardMedia, Typography, Grow, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';
import './portfolio.css';
import resumeData from '../../utils/resumeData';
import ImageGallery from '../../components/ImageGallery/ImageGallery';

const Portfolio = () => {

	const [tabValue, setTabValue] = useState("All");
	const [projectDialog, setProjectDialog] = useState(false);
	const tags = [];

	resumeData.projects.map((project) => (
		project.tags.map((tag) => (tags.push(tag)))
	));
	
	return (
		<Grid container className='section pb_45 pt_45'>
			{/* Title */}
			<Grid item className='section_title bottom_10'>
				<h6 className='section_title_text'>
					Portfolio
					<span></span>
				</h6>
			</Grid>

			{/* Tabs */}
			<Grid item xs={12}>
				<Tabs value={tabValue} indicatorColor='white' className='custom_tabs' onChange={(event, newTab) => setTabValue(newTab)}>
					<Tab label='All' value='All' className={tabValue === 'All' ? 'customTabs_item active' : 'customTabs_item'} />
					{[...new Set(tags)].map(
						(tag) => (
							<Tab
								label={tag}
								value={tag}
								className={tabValue === tag ? 'customTabs_item active' : 'customTabs_item'}
							/>
						)
					)}
				</Tabs>

				{/* Projects */}
				<Grid item xs={12}>
					<Grid container spacing={3}>
						{resumeData.projects.filter((project) => (tabValue === "All" || project.tags.includes(tabValue))).map((project) => (
							<Grid item xs={12} sm={6} md={4}>
								<Grow in timeout={1000}>
									<Card className='customCard' onClick={() => setProjectDialog(project)}>
										<CardActionArea>
											<CardMedia className='customCard_image' image={project.images[0]} title={project.title} />
											<CardContent>
												<Typography variant='body2' className='customCard_title'>{project.title}</Typography>
												<Typography variant='caption' className='customCard_caption'>{project.caption}</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grow>
							</Grid>
						))}
					</Grid>
				</Grid>

			</Grid>

			{/* Poup Dialog */}
			<Dialog open={projectDialog} onClose={() => setProjectDialog(false)} maxWidth={"lg"} fullWidth>
				<DialogTitle onClose={() => setProjectDialog(false)}>{projectDialog.title}</DialogTitle>
				<DialogActions className='projectDialog_actions'>
					{projectDialog.links?.map((link) => (
						<a className='projectDialog_icon' href={link.link} target='_blank' rel="noreferrer">{link.icon}</a>
					))}
				</DialogActions>
				<DialogContent style={{ height: "80vh" }}>
					{projectDialog.images &&
						<ImageGallery images={projectDialog.images} />
					}
					<Typography className='projectDialog_description'>{projectDialog.description}</Typography>
				</DialogContent>
			</Dialog>

		</Grid>
	)
};

export default Portfolio;